import "./App.css";
import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import axios from "axios";
import { PDFDocument } from "pdf-lib";

function App() {
  const [tryStatus, setTryStatus] = useState(false);
  const [pdfStatus, setPdfStatus] = useState();
  const [masterToken, setMasterToken] = useState();
  const [masterLocation, setMasterLocation] = useState();

  const CLIENT = process.env.REACT_APP_PDF_SERVICES_CLIENT_ID;
  const SECRET = process.env.REACT_APP_PDF_SERVICES_CLIENT_SECRET;

  var statusLoc = "";
  var thisAssetID;
  var myToken = "";
  var newFile;

  // useEffect(() => {
  //   newFile = new File([myPdf], "myTest.pdf", {
  //     type: "application/pdf",
  //   });
  //   saveAs(newFile);
  // }, []);

  // useEffect(() => {
  //   let thisAssetID;
  //   fetch("https://pdf-services.adobe.io/token", {
  //     method: "POST",
  //     body: new URLSearchParams({
  //       client_id: CLIENT,
  //       client_secret: SECRET,
  //     }),
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       myToken = data.access_token;
  //       setMasterToken(data.access_token);
  //       console.log(data);
  //       fetch("https://pdf-services.adobe.io/assets", {
  //         method: "POST",
  //         headers: {
  //           "X-API-Key": CLIENT,
  //           Authorization: myToken,
  //           "Content-Type": "application/json",
  //         },

  //         body: JSON.stringify({
  //           mediaType: "application/pdf",
  //         }),
  //       })
  //         .then((res) => res.json())
  //         .then((data) => {
  //           console.log(data);
  //           thisAssetID = data.assetID;
  //           fetch(data.uploadUri, {
  //             method: "PUT",
  //             headers: {
  //               "Content-Type": "application/pdf",
  //             },
  //             body: new File([myPdf], "output/mytest.pdf"),
  //           }).then(
  //             fetch("https://pdf-services.adobe.io/operation/exportpdf", {
  //               method: "POST",
  //               headers: {
  //                 "x-api-key": CLIENT,
  //                 "Content-Type": "application/json",
  //                 Authorization: myToken,
  //               },

  //               body: JSON.stringify({
  //                 assetID: thisAssetID,
  //                 targetFormat: "docx",
  //               }),
  //             }).then((response) => {
  //               console.log(myToken);
  //               for (const pair of response.headers.entries()) {
  //                 if (pair[0] === "location") statusLoc = `${pair[1]}`;
  //               }
  //               setMasterLocation(statusLoc);
  //               fetch(statusLoc, {
  //                 headers: {
  //                   Authorization: myToken,
  //                   "x-api-key": CLIENT,
  //                 },
  //               })
  //                 .then((response) => response.json())
  //                 .then((data) => {
  //                   console.log(myToken);
  //                   console.log(data);
  //                   if (data.status === "in progress") setTryStatus(true);
  //                 });
  //             })
  //           );
  //         });
  //     });
  // }, []);

  async function fillForm() {
    const formUrl = "https://mlinc.collinicoding.com/ComplyOfficeExhibit.pdf";
    const formPdfBytes = await fetch(formUrl).then((res) => {
      console.log(res);
      return res.arrayBuffer();
    });
    console.log(formPdfBytes);
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const form = pdfDoc.getForm();
    const serviceProviderLessor = form.getTextField("Service Provider Lessor");
    const commonName = form.getTextField("Common/Branded Name");
    const address = form.getTextField("Location Address");
    const city = form.getTextField("City");
    const state = form.getTextField("State");
    const zipCode = form.getTextField("Zip Code");
    const sqftLeased = form.getTextField("Square Feet Leased");
    const expectedTerm = form.getTextField("Expected Term of Lease");
    const numberOfDesks = form.getTextField("Number of Desks/Workstations");
    serviceProviderLessor.setText("This Is My Company");
    commonName.setText("The Common Name");
    address.setText("1234 My street address");
    city.setText("Dallas");
    state.setText("TX");
    zipCode.setText("75201");
    sqftLeased.setText("200");
    expectedTerm.setText("10-12 Months");
    numberOfDesks.setText("2");
    const pdfBytes = await pdfDoc.save();
    newFile = new File([pdfBytes], "myTest.pdf", {
      type: "application/pdf",
    });
    saveAs(newFile);
    // download(pdfBytes, "pdf-lib_form_creation_example.pdf", "application/pdf");
  }

  const tryAgain = async (theToken) => {
    console.log(masterToken);
    console.log(masterLocation);
    console.log(CLIENT);
    try {
      const data = await (
        await fetch(masterLocation, {
          headers: {
            Authorization: masterToken,
            "x-api-key": CLIENT,
          },
        })
      ).json();

      setPdfStatus(data.status);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h3>COMPLY OFFICE</h3>
        <h1>help</h1>
        <button onClick={() => fillForm()}>TRY AGAIN</button>
        <h2>Status: {pdfStatus}</h2>
      </header>
    </div>
  );
}

export default App;
